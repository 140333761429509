<template>
  <el-dialog
    class="export-excel-popup"
    title="Export dữ liệu ra excel"
    :width="isContinue ? '1024px' : '500px'"
    :visible="visible"
    :show-close="!exporting"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <el-form v-if="!isContinue" label-position="top">
      <el-form-item label="Tiêu đề file">
        <el-select v-model="activeTab" :disabled="true" class="w-100">
          <el-option
            v-for="item in titles"
            :key="item.value"
            :label="item.label || item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <div class="w-full" style="display: flex; justify-content: center">
        <el-row :gutter="24" class="w-100">
          <el-col :span="12">
            <el-form-item :label="`Từ dòng:`">
              <el-input-number
                v-model="from"
                :min="1"
                :max="to"
                :disabled="exporting"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="`Đến dòng:`">
              <el-input-number
                v-model="to"
                :min="from"
                :max="total"
                :disabled="exporting"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div v-if="exporting" class="mt-4">
        <el-progress
          :percentage="percentage"
          :stroke-width="12"
        ></el-progress>
      </div>
    </el-form>
    <div v-else>
      <el-row
        class="mt-0 mb-2"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <h3 class="text-center mt-0">{{ excelTitle }} ({{ checkedExportData.length }})</h3>
        <el-input
          v-model="searchText"
          class="w-50"
          placeholder="Nhập thông tin để tìm kiếm"
          @input="() => selectedRows = []"
        ></el-input>
      </el-row>
      <el-table
        ref="multipleTable"
        :data="filteredExportData.map((d, index) => ({ ...d, stt: index + 1 }))"
        style="width: 100%"
        height="450"
      >
        <el-table-column
          key="checkbox"
          label=""
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-checkbox
              :value="selectedRows.includes(scope.row.stt)"
              @change="(v) => handleSelectRow(scope.row.stt, v)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in [
            {
              key: 'stt',
              label: 'STT',
              fixed: 'left'
            },
            ...excelColumns
          ]"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
          :min-width="column.width"
          :fixed="column.fixed"
        >
          <template slot-scope="scope">
            <div class="text-limited text-limited__2" :title="scope.row[column.key]">
              {{ column.locale ? scope.row[column.locale][column.column_key] : scope.row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer">
      <el-button
        v-if="!isContinue"
        type="primary"
        :loading="exporting"
        @click="getExportData"
      >
        Tiếp tục
      </el-button>
      <el-row v-else type="flex" align="middle" justify="end">
        <el-button
          type="info"
          class="mr-2"
          :loading="exporting"
          @click="isContinue = false"
        >
          Quay lại
        </el-button>
        <el-button
          type="primary"
          icon="el-icon-download"
          class="mr-2"
          :loading="exporting"
          :disabled="checkedExportData.length === 0"
          @click="handleExport"
        >
          Export JSON
        </el-button>
        <vue-excel-xlsx
          :columns="excelColumns"
          :data="excelData"
          :file-name="`${excelTitle}`"
          :sheet-name="`${excelTitle}`"
          style="border: none; padding: 0"
        >
          <el-button
            type="primary"
            icon="el-icon-download"
            :disabled="checkedExportData.length === 0"
          >
            Download Excel
          </el-button>
        </vue-excel-xlsx>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listPlaces } from '@/services/place'
import { getComments } from '@/services/comment'
import { getCategory } from '@/services/category'
import { getArtifacts } from '@/services/artifact'
import { getTourDays } from '@/services/tour'
import { listStorages } from '@/services/storage'
import { getRoomTypes } from '@/services/room-type'

import { convertData, JSONToFile } from '@/utils/export'

export default {
  name: 'ExportExcelPopup',
  components: {},
  props: {
    visible: Boolean,
    order: String,
    total: Number,
    columns: Array,
    type: String,
    titles: Array,
    detail: Object,
    children: Boolean,
    activeTab: [String, Boolean]
  },
  data () {
    return {
      exporting: false,
      from: 1,
      to: this.total,
      exportData: [],
      isContinue: false,
      importing: false,
      exported: 0,
      exportSize: 100,
      selectedRows: [],
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language', 'constant']),
    excelTitle() {
      const selectedTitle = this.titles.find((t) => t.value === this.activeTab) || { name: '', label: '' }
      if (this.type === 'room_type') {
        return 'Hạng phòng'
      }
      if (this.type === 'tour_day') {
        return 'Lịch trình'
      }
      if (this.type === 'artifact') {
        return 'Danh sách'
      }
      if (this.type === 'category') {
        return 'Danh mục ' + (selectedTitle.name || selectedTitle.label)
      }
      return selectedTitle.label || selectedTitle.name
    },
    limit() {
      return this.to - this.from + 1
    },
    startPage() {
      return Math.floor((this.from / this.exportSize)) + 1
    },
    endPage() {
      return Math.floor((this.to / this.exportSize)) + 1
    },
    percentage() {
      if (this.limit > 0) {
        const result = Math.ceil((this.exported / this.limit * 100))
        return result > 100 ? 100 : result
      }
      return 0
    },
    requestParams() {
      let params = {
        locale: 'vi',
        order: this.order
      }
      if (['image', 'audio', 'video', 'tour_day', 'artifact', 'room_type'].includes(this.type)) {
        if (['image', 'audio', 'video'].includes(this.type)) {
          params = { ...params, file_type: this.type }
          if (this.detail) {
            params = { ...params, place_id: this.detail.id }
          }
        }
      } else if (this.type === 'category') {
        params = { ...params, place_type: this.activeTab }
      } else if (this.type === 'comment') {
        params = { ...params, approved: this.activeTab }
      } else {
        params = { ...params, place_types: this.type }
        if (!this.children) {
          params = { ...params, only_parent: true }
        } else {
          params = { ...params, only_children: true }
          if (this.detail && this.detail.type === this.type) {
            params = { ...params, parent_id: this.detail.id }
          }
        }
        if (this.detail && this.detail.type !== this.type) {
          params = { ...params, [`${this.detail.type}_id`]: this.detail.id }
        }
      }
      return params
    },
    locales() {
      return this.constant.constants.locales.filter((l) => l.code !== 'vi')
    },
    excelColumns() {
      const languageColumns = this.columns.filter((c) => c.language && !(c.languageExcludeTypes || []).includes(this.type))
      const lColumns = this.locales.map((l) => {
        return languageColumns.map((column) => ({
          ...column,
          locale: l.code,
          column_key: column.key,
          key: `${column.key}__${l.code}`,
          label: `${column.label} (${l.code})`
        }))
      }).flat()
      return [...this.columns, ...lColumns].map((c) => ({ ...c, label: c.label.toUpperCase(), field: c.key }))
    },
    filteredExportData() {
      return this.exportData.filter((d) => {
        if (this.searchText) {
          let result = false
          const keys = Object.keys(d)
          for (let i = 0; i < keys.length; i++) {
            if (d[keys[i]]) {
              if (typeof d[keys[i]] !== 'object') {
                const value = d[keys[i]].toString()
                if (value.toLowerCase().includes(this.searchText.toLowerCase())) {
                  result = true
                }
              } else {
                const oKeys = Object.keys(d[keys[i]])
                for (let j = 0; j < oKeys.length; j++) {
                  if (d[keys[i]][oKeys[j]]) {
                    if (typeof d[keys[i]][oKeys[j]] !== 'object') {
                      const value = d[keys[i]][oKeys[j]].toString()
                      if (value.toLowerCase().includes(this.searchText.toLowerCase())) {
                        result = true
                      }
                    }
                  }
                }
              }
            }
            if (result) {
              break
            }
          }
          return result
        }
        return true
      })
    },
    checkedExportData() {
      if (this.selectedRows.length > 0) {
        return this.filteredExportData.filter((d, index) => this.selectedRows.includes(index + 1))
      } else {
        return this.filteredExportData
      }
    },
    excelData() {
      return this.checkedExportData.map(d => {
        const data = {}
        this.excelColumns.forEach((column) => {
          if (column.locale) {
            data[`${column.key}`] = d[column.locale][column.column_key]
          } else {
            data[`${column.key}`] = d[column.key]
          }
        })
        return data
      })
    },
    jsonData() {
      const languageColumns = this.columns.filter((c) => c.language && !(c.languageExcludeTypes || []).includes(this.type))
      return this.checkedExportData.map(d => {
        const data = {}
        this.columns.forEach((column) => {
          data[`${column.key}`] = d[column.key]
        })
        this.locales.forEach(locale => {
          data[locale.code] = {}
          languageColumns.forEach((lColumn) => {
            data[locale.code][`${lColumn.key}`] = d[locale.code][lColumn.key]
          })
        })
        return data
      })
    }
  },
  methods: {
    handleSelectRow(stt, isChecked) {
      if (isChecked) {
        this.selectedRows = [...this.selectedRows, stt]
      } else {
        this.selectedRows = this.selectedRows.filter((r) => r !== stt)
      }
    },
    async handleListPlaces(params) {
      return await listPlaces(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return listPlaces({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            result[locale.code] = convertData(langData[i].result[index], this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListCategories(params) {
      return await getCategory(params, this.activeTab).then(async (response) => {
        response.result = response.result.map((r, index) => {
          const translations = r.translations
          const viData = translations.find((t) => t.locale === 'vi') || { name: '' }
          const result = convertData({ id: r.id, name: viData.name }, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            const localeData = translations.find((t) => t.locale === locale.code) || { name: '' }
            result[locale.code] = convertData({ id: r.id, name: localeData.name }, this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListArtifacts(params) {
      return await getArtifacts(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return getArtifacts({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const newR = {
            ...r,
            vrtour: (r.translation ? r.translation.vrtour : r.vrtour) || ''
          }
          const result = convertData(newR, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            const lData = langData[i].result[index]
            const newLangData = {
              ...lData,
              vrtour: (lData.translation ? lData.translation.vrtour : lData.vrtour) || ''
            }
            result[locale.code] = convertData(newLangData, this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListTourDays(params) {
      return await getTourDays(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return getTourDays({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            const lData = langData[i].result[index]
            result[locale.code] = convertData(lData, this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListStorages(params) {
      return await listStorages(params).then(async (response) => {
        response.result = response.result.map((r) => {
          if (this.type === 'video') {
            const translations = r.translations
            const viData = translations.find((t) => t.locale === 'vi') || { title: '', description: '' }
            const result = convertData({ id: r.id, title: viData.title, description: viData.description }, this.columns, this.constant)
            this.locales.forEach((locale, i) => {
              const localeData = translations.find((t) => t.locale === locale.code) || { title: '', description: '' }
              result[locale.code] = convertData({ id: r.id, title: localeData.title, description: localeData.description }, this.columns, this.constant)
            })
            return result
          }
          return convertData(r, this.columns, this.constant)
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },
    async handleListRoomTypes(params) {
      return await getRoomTypes(params).then(async (response) => {
        const requests = this.locales.map((l) => {
          return getRoomTypes({ ...params, locale: l.code })
        })
        const langData = await Promise.all(requests)
        response.result = response.result.map((r, index) => {
          const result = convertData(r, this.columns, this.constant)
          this.locales.forEach((locale, i) => {
            result[locale.code] = convertData(langData[i].result[index], this.columns, this.constant)
          })
          return result
        })
        return response
      }).catch(() => {
        return {
          result: []
        }
      })
    },

    async getExportData() {
      this.exportData = []
      this.selectedRows = []
      let exportData = []
      this.exporting = true
      let page = this.startPage
      let params = this.requestParams
      while (page <= this.endPage) {
        params = {
          ...params,
          page,
          per_page: this.exportSize
        }
        let request = null
        if (this.type === 'comment') {
          request = getComments(params)
        } else if (this.type === 'category') {
          request = this.handleListCategories(params)
        } else if (this.type === 'artifact') {
          request = this.handleListArtifacts(params)
        } else if (this.type === 'room_type') {
          request = this.handleListRoomTypes(params)
        } else if (this.type === 'tour_day') {
          request = this.handleListTourDays(params)
        } else if (['image', 'audio', 'video'].includes(this.type)) {
          request = this.handleListStorages(params)
        } else {
          request = this.handleListPlaces(params)
        }
        await request.then((response) => {
          exportData = [...exportData, ...response.result]
        }).catch(() => {
          exportData = [...exportData]
        })
        this.exported = exportData.length
        page = page + 1
      }
      const offset = this.from - (this.exportSize * (this.startPage - 1)) - 1
      this.exportData = exportData.slice(
        offset > 0 ? offset : 0,
        this.limit + offset
      )
      setTimeout(() => {
        this.isContinue = true
        this.exporting = false
      }, 1000)
    },

    handleExport() {
      JSONToFile(this.jsonData, this.excelTitle)
      this.$emit('close')
      this.$notify.success({
        title: 'Thông báo !',
        message: 'Lưu file JSON thành công !'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.export-excel-popup {
  .el-select, .el-input-number {
    width: 100%;
  }
}
</style>
<style lang="scss">
.export-excel-popup {
  .el-dialog__body {
    padding-top: 10px;
  }
}
</style>
