<template>
  <div>
    <el-dialog
      top="80px"
      title
      class="show-images"
      width="1020px"
      :visible="visible"
      :show-close="false"
      append-to-body
      @close="$emit('close')"
    >
      <div v-if="visible" class="show-images__content">
        <el-carousel indicator-position="outside" :autoplay="false" :initial-index="selectedIndex">
          <el-carousel-item v-for="img in listImages" :key="img.id">
            <div class="preview-image">
              <img :src="img.url" alt="href" />
              <div class="preview-image__title" @click="handleOpen(img)">
                <h3 class="text-limited">Tác giả: {{ img.author || '----' }} / Tên: {{ img.name || '----' }}</h3>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </el-dialog>
    <el-dialog
      width="500px"
      append-to-body
      title="Sửa ảnh"
      :visible="editVisible"
      :show-close="true"
      @close="editVisible = false"
    >
      <el-form :model="form" label-position="top">
        <el-form-item label="Tên tác giả" prop="">
          <el-input v-model="form.author" :disabled="callingAPI" placeholder="Nhập vào tên tác giả"></el-input>
        </el-form-item>
        <el-form-item label="Tên hình ảnh" prop="name">
          <el-input v-model="form.name" :disabled="callingAPI" placeholder="Nhập vào tên hình ảnh"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
        <el-button type="primary" :loading="callingAPI" @click="handleUpdateImage">Lưu</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { updateStorage } from '@/services/storage'
export default {
  name: 'ShowImagesPopup',
  props: {
    visible: Boolean,
    selectedImg: Object,
    images: Array
  },
  data() {
    return {
      editVisible: false,
      callingAPI: false,
      listImages: this.images,
      form: {
        id: '',
        name: '',
        author: ''
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    selectedIndex() {
      return this.images.findIndex((i) => i.id === this.selectedImg.id)
    }
  },
  methods: {
    handleOpen(img) {
      this.editVisible = true
      this.form = {
        id: img.id,
        name: img.name,
        author: img.author
      }
    },
    handleClose() {
      this.editVisible = false
      this.form = {
        name: '',
        author: '',
        id: ''
      }
    },
    handleUpdateImage() {
      this.callingAPI = true
      updateStorage({
        ...this.form,
        locale: this.language
      }).then(() => {
        const index = this.listImages.findIndex((i) => i.id === this.form.id)
        if (index >= 0) {
          this.listImages[index].name = this.form.name
          this.listImages[index].author = this.form.author
        }
        this.callingAPI = false
        this.handleClose()
        this.$emit('reload')
        this.$notify.success({
          title: 'Thông báo',
          message: 'Sửa ảnh thành công thành công'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.show-images {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    > div {
      text-align: center;
    }
    .el-carousel {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style lang="scss">
.show-images {
  .el-dialog {
    &__header {
      display: none;
    }
    &__body {
      padding: 10px;
      height: 600px;
    }
    .el-carousel__container {
      height: 100%;
      .el-carousel__item {
        .preview-image {
          width: 100%;
          height: 100%;
          position: relative;
          &__title {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            height: 50px;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.3);
            display: flex;
            align-items: center;
            justify-content: center;
            h3 {
              color: white
            }
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .el-carousel__arrow {
        i {
          font-size: 21px;
          font-weight: bold;
        }
      }
    }
    .el-carousel__indicators--outside {
      display: none;
    }
  }
}
</style>
