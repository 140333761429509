import { getCountry, getArea, getCategory, getTag } from '@/utils/filters'

function countryId(row, constant) {
  const c = getCountry(row.category_id, constant.constants.countries, 'name')
  return c ? c.id : null
}

function areaId(row, constant) {
  const c = getArea(row.area_id, constant.constants.areas, 'name')
  return c ? c.id : null
}

function openingDay(row) {
  if (row.opening_day) {
    if (row.opening_day.toLowerCase().includes('cả tuần')) {
      return [0, 1, 2, 3, 4, 5, 6]
    }
    const result = row.opening_day.split(',').map((r) => r.trim())
    return result.map((r) => {
      if (r.toLowerCase() === 'thứ hai') {
        return 0
      }
      if (r.toLowerCase() === 'thứ ba') {
        return 1
      }
      if (r.toLowerCase() === 'thứ tư') {
        return 2
      }
      if (r.toLowerCase() === 'thứ năm') {
        return 3
      }
      if (r.toLowerCase() === 'thứ sáu') {
        return 4
      }
      if (r.toLowerCase() === 'thứ bảy') {
        return 5
      }
      if (r.toLowerCase() === 'chủ nhật') {
        return 6
      }
    })
  }
  return []
}

function openingTime(row) {
  const result = (row.opening_time || '').split('-').map((r) => r.trim())
  const regTime = /(^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$)/
  const regExp = new RegExp(regTime)
  const openTime = regExp.test(result[0]) ? result[0] : '06:00'
  const closeTime = regExp.test(result[1]) ? result[1] : (result[1] === '24:00' ? '23:59' : '21:00')
  return {
    open_time: openTime,
    close_time: closeTime
  }
}

function categoryId(row, constant, type) {
  const categories = constant.constants.place_categories.filter((c) => c.place_type === type)
  const category = getCategory(row.category_id, categories, 'name')
  return category ? category.id : null
}

function youtubeLinks(row) {
  if (row.youtube_links) {
    return row.youtube_links.split(',').map((l) => {
      const regExp = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
      if (l.match(regExp)) {
        let link = l.split('&')[0]
        if (l.includes('watch?v=')) {
          link = l.replace('watch?v=', 'embed/').split('&')[0]
        } else if (l.includes('/shorts/')) {
          link = l.replace('/shorts/', '/embed/').split('&')[0]
        }
        return link.trim()
      }
      return null
    }).filter(Boolean)
  }
  return []
}

function priceInfo(row) {
  if (row.price_info) {
    if (row.price_info.toLowerCase().includes('miễn phí')) {
      return {
        free: true,
        minimum: 0,
        maximum: 0
      }
    }
    const result = row.price_info.split('-').map((r) => r.trim())
    return {
      free: false,
      minimum: Number(result[0] || 0),
      maximum: Number(result[1] || 0)
    }
  }
  return {
    free: true,
    minimum: 0,
    maximum: 0
  }
}

function placeDuration(row) {
  if (row.place_duration) {
    try {
      const result = row.place_duration.split('-').map((r) => r.trim())
      return {
        started_at: result[0] ? `${result[0].split('/').reverse().join('-')}T00:00:00}` : null,
        ended_at: result[1] ? `${result[1].split('/').reverse().join('-')}T00:00:00}` : null
      }
    } catch (error) {
      return {}
    }
  }
  return {}
}

function tagIds(row, constant) {
  if (row.tags) {
    const result = row.tags.split(',').map((r) => r.trim())
    return result.map((r) => {
      const tag = getTag(r, (constant.tags || []), 'name')
      return tag ? tag.id : ''
    }).filter(Boolean)
  }
  return []
}

function dob(row) {
  try {
    return row.date_of_birth ? `${row.date_of_birth.split('/').reverse().join('-')}T00:00:00}` : null
  } catch (error) {
    return null
  }
}

function dod(row) {
  try {
    return row.date_of_death ? `${row.date_of_death.split('/').reverse().join('-')}T00:00:00}` : null
  } catch (error) {
    return null
  }
}

export function convertData(row, columns, constant, type) {
  const data = {}
  columns.forEach(column => {
    if (row[column.key]) {
      switch (column.key) {
        case 'opening_time':
          data[column.key] = openingTime(row)
          break
        case 'category_id':
          data[column.key] = categoryId(row, constant, type)
          break
        case 'location_address':
        case 'location_longitude':
        case 'location_latitude':
          data['location'] = {
            ...data.location,
            place_id: '',
            address: row.location_address,
            longitude: row.location_longitude,
            latitude: row.location_latitude
          }
          break
        case 'youtube_links':
          data[column.key] = youtubeLinks(row)
          break
        case 'price_info':
          data[column.key] = priceInfo(row)
          break
        case 'place_duration':
          data['started_at'] = placeDuration(row).started_at || null
          data['ended_at'] = placeDuration(row).ended_at || null
          break
        case 'opening_day':
          data[column.key] = openingDay(row)
          break
        case 'country_id':
          data[column.key] = countryId(row, constant)
          break
        case 'area_id':
          data[column.key] = areaId(row, constant)
          break
        case 'contact_info_phone':
        case 'contact_info_email':
        case 'contact_info_website':
          data['contact_info'] = {
            ...data.contact_info,
            phone: row.contact_info_phone,
            email: row.contact_info_email,
            website: row.contact_info_website
          }
          break
        case 'tags':
          data['tag_ids'] = tagIds(row, constant)
          break
        case 'date_of_birth':
          data['dob'] = dob(row)
          break
        case 'date_of_death':
          data['dod'] = dod(row)
          break
        case 'description':
          if (isNaN(row[column.key])) {
            data['description'] = ''
          } else {
            data['description'] = row['description']
          }
          break
        case 'is_avatar':
          if (row[column.key].toLowerCase() === 'yes') {
            data['is_avatar'] = true
          } else {
            data['is_avatar'] = false
          }
          break
        default:
          if (column.type === 'number') {
            data[column.key] = Number(`${row[column.key] || 0}`)
          } else {
            data[column.key] = `${row[column.key] || ''}`
          }
          break
      }
    }
  })
  return data
}
