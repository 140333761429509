<template>
  <el-dialog
    top="80px"
    title
    class="qrcode-popup"
    width="620px"
    :visible="visible"
    :show-close="false"
    @close="$emit('close')"
  >
    <div class="qrcode-popup__content">
      <div v-if="!row || !row.qr_code">
        <p>Hiện tại chưa có thông tin mã QR</p>
        <p>Vui lòng tạo thêm thông tin.</p>
      </div>
      <vue-qr
        v-else
        :text="row.qr_code"
        :size="600"
        :margin="0"
        :logo-margin="5"
        :logo-scale="0.2"
      ></vue-qr>
    </div>
  </el-dialog>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'QrcodePopup',
  components: {
    VueQr
  },
  props: {
    visible: Boolean,
    row: Object
  }
}
</script>
<style lang="scss" scoped>
.qrcode-popup {
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    > div {
      text-align: center;
    }
  }
}
</style>
<style lang="scss">
.qrcode-popup {
  .el-dialog {
    &__header {
      display: none;
    }
    &__body {
      padding: 10px;
      height: 600px;
    }
  }
}
</style>
