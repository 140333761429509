<template>
  <div class="address one-component-detail-admin">
    <div class="title-component-admin">Địa chỉ</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Nhập thông tin địa chỉ để tìm kiếm">
        <select-address :value="data.location" @input="(value) => data.location = value" />
      </el-form-item>
      <el-form-item label="Toạ độ bản đồ (Kinh độ - Vĩ độ)">
        <el-input
          v-model="latitude_longitude"
          clearable
          @input="changeLatitudeLongitude"
        />
      </el-form-item>
      <el-form-item v-if="type !== 'country'" label="Đất nước">
        <el-select v-model="data.country_id" filterable suffix-icon="fas fa-globe" clearable>
          <el-option v-for="c in countries" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!['country', 'area'].includes(type)" label="Thành phố">
        <el-select v-model="data.area_id" filterable suffix-icon="el-icon-place" clearable>
          <el-option v-for="a in areas" :key="a.id" :label="a.name" :value="a.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SelectAddress from '../items/Address.vue'

export default {
  name: 'Address',
  components: {
    SelectAddress
  },
  props: {
    data: Object,
    type: String
  },
  data () {
    return {
      latitude_longitude: `${this.data.location.latitude}, ${this.data.location.longitude}`
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    areas() {
      return this.constant.constants.areas
    },
    countries() {
      return this.constant.constants.countries
    }
  },
  methods: {
    changeLatitudeLongitude(v) {
      const result = v ? v.split(',') : []
      const latitude = Number(result[0] || '')
      const longitude = Number(result[1] || '')
      this.data.location.latitude = isNaN(latitude) ? 0 : latitude
      this.data.location.longitude = isNaN(longitude) ? 0 : longitude
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  .el-select {
    width: 100%;
  }
}
</style>
