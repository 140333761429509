<template>
  <div class="artifacts-tab shared-index">
    <el-row class="table-title" type="flex" align="center">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane name="artifacts">
            <div slot="label">
              Danh sách
              <span>({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div style="display: flex; align-items: center;">
          <el-button
            type="primary"
            icon="el-icon-circle-plus-outline"
            size="small"
            class="mr-2"
            @click="createTag"
          >Thêm</el-button>
          <import-excel-button
            class="mr-2"
            size="small"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="'artifact'"
            :titles="importExportTitles"
            :active-tab="'artifact'"
            @reload="handleChangeTab"
          />
          <export-excel-button
            class="mr-2"
            size="small"
            :disabled="loading"
            :total="total"
            :columns="excelColumns"
            :type="'artifact'"
            :titles="importExportTitles"
            :active-tab="'artifact'"
          />
          <el-button
            type="primary"
            icon="el-icon-refresh-right"
            size="small"
            @click="handleChangeTab"
          >Làm mới</el-button>
        </div>
      </div>
    </el-row>
    <list-artifacts
      :loading="loading"
      :data="allArtifacts"
      @reload="handleReloadAfterDelete"
      @update="updateArtifact"
    ></list-artifacts>
    <el-row type="flex" justify="end" class="my-4">
      <el-pagination
        :current-page="page"
        :page-size="limit"
        :total="total"
        background
        layout="total, prev, pager, next"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <artifact-popup
      v-if="formVisible"
      :visible="formVisible"
      :artifact="selectedArtifact"
      @close="formVisible = false"
      @reload="loadData"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListArtifacts from './components/List.vue'
import ArtifactPopup from '../../components/popups/Artifact.vue'
import { getArtifacts } from '@/services/artifact'
import TYPE from '@/data/type'

import ImportExcelButton from '../../components/buttons/ImportExcel.vue'
import ExportExcelButton from '../../components/buttons/ExportExcel.vue'

export default {
  name: 'Artifacts',
  components: {
    ListArtifacts,
    ArtifactPopup,
    ImportExcelButton,
    ExportExcelButton
  },
  data() {
    return {
      loading: false,
      allArtifacts: [],
      selectedArtifact: null,
      activeTab: 'artifacts',
      total: 0,
      limit: 10,
      page: 1,
      searchText: '',
      formVisible: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      return {
        page: this.page,
        per_page: this.limit,
        locale: this.language
      }
    },
    excelColumns() {
      const eColumns = this.$route.meta.data.index.excelColumns
      return (eColumns || []).map((c) =>
        TYPE.EXCEL_COLUMNS.find((column) => c === column.key)
      )
    },
    importExportTitles() {
      return [{ name: 'Danh sách', value: 'artifact' }]
    }
  },
  watch: {
    language: 'loadData'
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),

    loadData() {
      this.loading = true
      this.formVisible = false
      getArtifacts(this.params).then((response) => {
        this.allArtifacts = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleChangePage(page) {
      this.page = page
      this.loadData()
    },
    createTag() {
      this.selectedArtifact = null
      this.formVisible = true
    },
    updateArtifact(data) {
      this.selectedArtifact = data
      this.formVisible = true
    },
    handleChangeTab() {
      this.page = 1
      this.loadData()
    },
    handleReloadAfterDelete() {
      if (this.allArtifacts.length === 1 && this.page > 1) {
        this.page = this.page - 1
      }
      this.loadData()
    }
  }
}
</script>

<style lang="scss" scoped>
.artifacts-tab {
  .table-title {
    &__left {
      max-width: calc(100% - 200px);
      .el-tabs {
        max-width: 100%;
      }
    }
  }
}
</style>
