<template>
  <div class="user-page shared-index">
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleChangeTab">
          <el-tab-pane
            v-for="title in titles"
            :key="title.index"
            :label="pageTitle(title.value)"
            :name="title.value"
          ></el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createVisible = true"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleReloadData"
        >Làm mới</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên, email"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <div class="table">
      <list-user
        :staff-type="staffType"
        :list-user="listUser"
        :loading="loading"
        @reload="loadData"
        @update="updateUser"
        @history="historyUser"
      ></list-user>
    </div>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <create-user-popup
      v-if="createVisible"
      :visible="createVisible"
      :type="type"
      :staff-type="activeTab"
      :all-department="allDepartment"
      :all-working-locations="allWorkingLocations"
      @close="createVisible = false"
      @reload="loadData"
    />
    <update-user-popup
      v-if="updateVisible"
      :visible="updateVisible"
      :user="selectedUser"
      :all-department="allDepartment"
      :all-working-locations="allWorkingLocations"
      @close="updateVisible = false"
      @reload="loadData"
    />
    <history-user-popup
      v-if="historyVisible"
      :visible="historyVisible"
      :user="selectedUser"
      @close="historyVisible = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getDepartments } from '@/services/department'
import { getWorkingLocations } from '@/services/working-location'
import { getUsers } from '@/services/user'
import ListUser from './components/List.vue'
import CreateUserPopup from './components/popups/Create.vue'
import UpdateUserPopup from './components/popups/Update.vue'
import HistoryUserPopup from './components/popups/History.vue'

export default {
  name: 'SharedUser',
  components: {
    ListUser,
    CreateUserPopup,
    UpdateUserPopup,
    HistoryUserPopup
  },
  props: {
    type: String,
    staffType: String,
    titles: Array
  },
  data() {
    return {
      loading: true,
      searchText: '',
      activeTab: this.staffType,
      limit: 10,
      total: 0,
      page: 1,
      listUser: [],
      createVisible: false,
      updateVisible: false,
      historyVisible: false,
      selectedUser: {},
      allDepartment: [],
      allWorkingLocations: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    params() {
      let p = {
        page: this.page,
        per_page: this.limit,
        user_types: this.type,
        search_text: this.searchText
      }
      if (this.type === 'staff') {
        p = { ...p, staff_types: this.activeTab }
      }
      return p
    },
    otherParams() {
      return {
        page: 1,
        per_page: 100,
        locale: this.language
      }
    }
  },
  watch: {
    language: 'loadData'
  },
  mounted() {
    this.listDepartment()
    this.listWorkingLocation()
    this.loadData()
  },
  methods: {
    async listDepartment() {
      await getDepartments(this.otherParams).then((response) => {
        this.allDepartment = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async listWorkingLocation() {
      await getWorkingLocations(this.otherParams).then((response) => {
        this.allWorkingLocations = response.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    async loadData() {
      this.loading = true
      this.createVisible = false
      this.updateVisible = false
      await getUsers(this.params).then((response) => {
        this.listUser = response.result
        this.total = response.total
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    updateUser(user) {
      this.selectedUser = user
      this.updateVisible = true
    },

    historyUser(user) {
      this.selectedUser = user
      this.historyVisible = true
    },

    handleReloadData() {
      this.searchText = ''
      this.page = 1
      this.loadData()
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeTab() {
      this.page = 1
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    },

    pageTitle(currentTitle) {
      const title = this.titles.find((t) => t.value === currentTitle)
      return `${title.label} ${this.activeTab === currentTitle && !this.loading ? `(${this.total})` : ''}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
