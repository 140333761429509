import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getRoomTypes(params) {
  return request({
    url: `${ENDPOINT.LIST_ROOM_TYPES}`,
    method: 'get',
    params
  })
}

export function createRoomType(data) {
  return request({
    url: `${ENDPOINT.ROOM_TYPE}`,
    method: 'post',
    data
  })
}

export function updateRoomType(data) {
  return request({
    url: `${ENDPOINT.ROOM_TYPE}`,
    method: 'patch',
    data
  })
}

export function deleteRoomType(params) {
  return request({
    url: `${ENDPOINT.ROOM_TYPE}`,
    method: 'delete',
    params
  })
}
