<template>
  <div class="shared-actions">
    <el-row type="flex" align="middle" justify="center">
      <el-tooltip
        v-for="action in tableActions"
        :key="action.key"
        :content="action.label"
        placement="top"
      >
        <router-link
          v-if="action.key === 'edit'"
          :to="getToShowRouter(data)"
        >
          <el-button
            circle
            class="mx-2"
            size="small"
            :icon="action.icon"
            :type="action.type"
          ></el-button>
        </router-link>
        <el-button
          v-else
          circle
          size="small"
          :icon="action.icon"
          :type="action.type"
          @click="handleAction(action.key, data)"
        ></el-button>
      </el-tooltip>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TYPE from '@/data/type'

import { deletePlace } from '@/services/place'
import { deleteComment, updateComment } from '@/services/comment'

import { getToShowRouter } from '@/utils/filters'

export default {
  name: 'SharedActions',
  components: {
  },
  props: {
    type: String,
    data: Object,
    actions: Array,
    routerData: Object,
    tableData: Array,
    page: Number
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['language']),
    tableActions() {
      let actions = this.actions
      if (this.$route.name.includes('Info')) {
        actions = actions.filter((a) => a !== 'edit')
      }
      if (this.$route.name.includes('Detail')) {
        actions = actions.filter((a) => a !== 'detail')
      }
      if (this.$route.name.includes('Related')) {
        actions = actions.filter((a) => a !== 'related')
      }
      if (this.type === 'comment') {
        actions = actions.filter((a) => a !== (this.activeTab === 'true' ? 'browser' : 'unbrowser'))
      }
      return actions.map((a) =>
        TYPE.ALL_ACTIONS.find((action) => a === action.key)
      )
    },

    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    }
  },
  methods: {
    getToShowRouter,
    handleAction(key, row) {
      switch (key) {
        case 'showQR':
          this.$emit('showQR')
          break
        case 'copy':
          this.$clipboard(`${window.location.origin}/place/${row.id}?locale=${this.language}`)
          this.$notify.success({
            title: 'Thông báo !',
            message: 'Đã copy thành công !'
          })
          break
        case 'edit':
          this.$router.push({ name: `${this.routerData.name}Info`, params: { id: row.id }})
          break
        case 'detail':
          this.$router.push({ name: `${this.routerData.name}Detail`, params: { id: row.id }})
          break
        case 'related':
          this.$router.push({ name: `${this.routerData.name}Related`, params: { id: row.id }})
          break
        case 'browser':
          updateComment({ id: row.id, approved: true }).then(() => {
            this.$notify.success({
              title: 'Thông báo',
              message: 'Phê duyệt thành công.'
            })
            this.$emit('reloadPage')
          }).catch((error) => {
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
          break
        case 'unbrowser':
          updateComment({ id: row.id, approved: false }).then(() => {
            this.$notify.success({
              title: 'Thông báo',
              message: 'Bỏ phê duyệt thành công.'
            })
            this.$emit('reloadPage')
          }).catch((error) => {
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
          break
        case 'delete':
          this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
            confirmButtonText: 'Xóa',
            cancelButtonText: 'Đóng',
            type: 'warning'
          }).then(() => {
            let request = null
            if (this.type === 'comment') {
              request = deleteComment({ ids: row.id })
            } else {
              request = deletePlace(row.id)
            }
            request.then(() => {
              this.$notify.success({
                title: 'Thông báo',
                message: 'Xóa thành công'
              })
              if (this.tableData.length === 1) {
                this.$emit('changePage', this.page > 1 ? this.page - 1 : this.page)
              }
              this.$emit('reloadPage')
              if (['country', 'area'].includes(this.type)) {
                this.$emit('reloadConstants')
              }
            }).catch((error) => {
              this.$notify.error({
                title: 'Thông báo',
                message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
              })
            })
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
