<template>
  <el-table v-loading="loading" :data="data" class="artifacts">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Ưu tiên" prop="ordered" width="80">
    </el-table-column>
    <el-table-column label="Place ID" prop="place_id" width="150">
    </el-table-column>
    <el-table-column width="60">
      <template slot-scope="scope">
        <span class="avatar-img">
          <img
            :src="
              scope.row.avatar && scope.row.avatar.url
                ? scope.row.avatar.url
                : imageDefaultUrl
            "
            alt="href"
          />
          <i
            v-if="scope.row.vrtour"
            class="fas fa-play-circle"
            title="Tour VR"
          ></i>
          <i v-if="scope.row.audio" class="fas fa-volume-up" title="Audio"></i>
        </span>
      </template>
    </el-table-column>
    <el-table-column label="Tên" prop="name" min-width="100">
      <template slot-scope="scope">
        <a href="javascript:;" class="text-primary text-limited">{{
          scope.row.name
        }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Mô tả" prop="description">
      <template slot-scope="scope">
        <p class="text-limited">{{
          scope.row.description
        }}</p>
      </template>
    </el-table-column>
    <el-table-column
      label="Ngày tạo"
      prop="created_at"
      width="100"
    ></el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column
      label="Thao tác"
      width="150"
      align="center"
      fixed="right"
    >
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa thông tin" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="handleUpdate(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="handleDelete(scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteArtifact } from '@/services/artifact'
export default {
  name: 'ListArtifacts',
  props: {
    data: Array,
    loading: Boolean
  },
  data() {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    handleDelete(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteArtifact({ id: row.id })
          .then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: `Xóa thẻ thành công`
            })
            this.$emit('reload')
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleUpdate(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.artifacts {
  img {
    &.language {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      margin: 0 5px;
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
  .avatar-img {
    position: relative;
    img {
      width: 48px !important;
      height: 48px !important;
      object-fit: cover;
      border-radius: 50%;
    }
    svg {
      position: absolute;
    }
    .fa-volume-up {
      right: -2px;
      bottom: 34px;
      color: #409eff;
      font-size: 18px;
    }
    .fa-play-circle {
      right: -2px;
      top: -2px;
      font-size: 20px;
      color: #67c23a;
    }
  }
}
</style>
