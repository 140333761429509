<template>
  <el-dialog
    top="80px"
    width="600px"
    class="package-form"
    :title="!board ? 'Thêm mới' : 'Chỉnh sửa'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Tên danh sách" prop="name">
        <el-input v-model="form.name" placeholder="Nhập vào tên"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input v-model="form.description" type="textarea" placeholder="Nhập vào mô tả"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdate">{{ !board ? 'Tạo' : 'Cập nhật' }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createBoard, updateBoard } from '@/services/board'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'UpdateBoard',
  props: {
    board: Object,
    visible: Boolean,
    placeTypes: Array
  },
  data() {
    return {
      callingAPI: false,
      searching: false,
      searchText: '',
      organizations: [],
      form: {
        name: this.board ? this.board.name : '',
        description: this.board ? this.board.description : '',
        organizationId: this.board ? this.board.organization_id : ''
      },
      rules: {
        name: [
          { required: true, message: 'Tên không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    dataInput() {
      const data = {
        locale: this.language,
        name: this.form.name,
        description: this.form.description
      }
      if (this.board) {
        data.id = this.board.id
      } else {
        data.code = `${this.form.name}_${uuidv4()}`
        if (this.form.organizationId) {
          data.organization_id = this.form.organizationId
        }
      }
      return data
    },

    params() {
      return {
        page: 1,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  methods: {
    handleUpdate() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.board) {
            createBoard(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Tạo mới thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            updateBoard(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Cập nhật thành công'
              })
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
