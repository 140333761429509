<template>
  <el-table v-loading="loading" :data="data" class="tour-days">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tour ID" prop="place_id" width="150">
    </el-table-column>
    <el-table-column label="Thời gian (h)" prop="time" min-width="100" align="right">
      <template slot-scope="scope">
        <p class="text-limited">{{
          scope.row.time
        }}</p>
      </template>
    </el-table-column>
    <el-table-column label="Khoảng cách (Km)" prop="distance" align="right">
      <template slot-scope="scope">
        <p class="text-limited">{{
          scope.row.distance
        }}</p>
      </template>
    </el-table-column>
    <el-table-column
      label="Ngày tạo"
      prop="created_at"
      width="100"
    ></el-table-column>
    <el-table-column label="Ngôn ngữ" width="250" align="center">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column
      label="Thao tác"
      width="150"
      align="center"
      fixed="right"
    >
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa thông tin" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="handleUpdate(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="handleDelete(scope.row)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
import { deleteSchedule } from '@/services/tour'
export default {
  name: 'ListTourDays',
  props: {
    data: Array,
    loading: Boolean
  },
  data() {
    return {
      imageDefaultUrl: '/default-image.jpeg'
    }
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    handleDelete(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteSchedule({ ids: row.id })
          .then(() => {
            this.$notify.success({
              title: 'Thành công',
              message: `Xóa thẻ thành công`
            })
            this.$emit('reload')
          })
          .catch(() => {
            this.loading = false
            this.$notify.error({
              title: 'Thông báo',
              message: 'Không thể xóa!'
            })
          })
      })
    },

    handleUpdate(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.tour-days {
  img {
    &.language {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      margin: 0 5px;
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
}
</style>
