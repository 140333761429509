<template>
  <el-dialog
    width="600px"
    class="artifact-group"
    :title="`Quản lý nhóm ${group.summary}`"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="group" label-position="top">
      <el-form-item label="Tên nhóm">
        <el-input v-model="group.summary" disabled></el-input>
      </el-form-item>
      <el-form-item label="Danh sách">
        <el-select
          v-model="group.artifact_ids"
          filterable
          :disabled="callingAPI || loading"
          multiple
        >
          <el-option
            v-for="c in artifacts"
            :key="c.id"
            :label="c.name"
            :value="c.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        :disabled="callingAPI || loading"
        @click="$emit('close')"
      >Hủy</el-button>
      <el-button
        type="primary"
        :loading="callingAPI || loading"
        @click="handleSaveGroupInfo"
      >Cập nhật</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getArtifacts, updateClassification } from '@/services/artifact'
import TYPE from '@/data/type'

export default {
  name: 'ArtifactGroup',
  props: {
    visible: Boolean,
    group: Object,
    type: String,
    object: Object
  },
  data() {
    return {
      callingAPI: false,
      loading: false,
      artifacts: []
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    params() {
      const p = {
        locale: this.language,
        object_type: `${this.type}-item`,
        order: '-ordered',
        page: 1,
        per_page: 100
      }
      return {
        ...p,
        place_id: this.object.id
      }
    },
    payload() {
      const p = {
        id: this.group.id,
        artifact_ids: this.group.artifact_ids,
        description: '',
        locale: this.language
      }
      return {
        ...p,
        place_id: this.object.id
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.loading = true
      getArtifacts(this.params).then((response) => {
        this.artifacts = response.result
        this.group.artifact_ids = this.group.artifacts.map((a) => a.id)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleSaveGroupInfo() {
      this.callingAPI = true
      updateClassification(this.payload).then(() => {
        this.callingAPI = false
        this.$emit('close')
        this.$emit('reload')
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật nhóm thành công.'
        })
      }).catch(() => {
        this.callingAPI = false
        this.$notify.error({
          title: 'Thông báo',
          message: 'Không thể cập nhật nhóm.'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.artifact-group {
  .el-select {
    width: 100%;
  }
}
</style>
