<template>
  <el-table v-loading="loading" :data="data" class="media">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Preview" prop="url" :width="type === 'audio' ? 300 : 100">
      <template slot-scope="scope">
        <span
          v-if="type === 'image'"
          :key="scope.row.id"
          class="avatar-img"
          style="cursor: pointer"
          @click="() => $emit('update', scope.row)"
        >
          <img
            :src="scope.row.url"
            alt="href"
          />
        </span>
        <span
          v-if="type === 'video'"
          :key="scope.row.id"
          class="avatar-img"
          style="cursor: pointer"
          @click="() => $emit('show', scope.row)"
        >
          <img v-if="scope.row.avatar" :src="scope.row.avatar.url" alt="href" />
          <img v-else src="@/assets/images/thumbnail-overlay.png" alt="href" />
        </span>
        <audio
          v-if="type === 'audio'"
          :key="scope.row.id"
          controls
          style="width: 100%"
        >
          <source :src="scope.row.url" />
        </audio>
      </template>
    </el-table-column>
    <el-table-column :label="type !== 'video' ? 'Tên' : 'Tiêu đề'" prop="name">
      <template slot-scope="scope">
        <span class="text-limited">{{ type !== 'video' ? scope.row.name : scope.row.title }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="type !== 'video' ? 'Tác giả' : 'Mô tả'" prop="name">
      <template slot-scope="scope">
        <span class="text-limited">{{ type !== 'video' ? scope.row.author : scope.row.description }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at">
      <template slot-scope="scope">
        <span>{{ scope.row.created_at }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Ngôn ngữ" align="center" width="150">
      <template slot-scope="scope">
        <el-row type="flex" align="middle" justify="center">
          <img
            v-for="locale in locales"
            :key="locale.code"
            class="language"
            :class="{ inactive: !scope.row.translations || !scope.row.translations.find((t) => t.locale === locale.code)}"
            :src="require(`@/assets/images/${locale.code}.png`)"
            alt="href"
          />
        </el-row>
      </template>
    </el-table-column>
    <el-table-column
      label="Thao tác"
      width="150"
      align="center"
      fixed="right"
    >
      <template slot-scope="scope">
        <el-tooltip content="Copy Link" placement="top">
          <el-button
            icon="el-icon-copy-document"
            circle
            size="small"
            @click="() => copyLink(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="() => $emit('update', scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="() => $emit('delete', [scope.row])"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ListMedia',
  props: {
    type: String,
    data: Array,
    loading: Boolean
  },
  computed: {
    ...mapGetters(['constant']),
    locales() {
      return this.constant.constants.locales
    }
  },
  methods: {
    copyLink(row) {
      this.$clipboard(row.url)
      this.$notify.success({
        title: 'Thông báo !',
        message: 'Đã copy thành công !'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.media {
  img {
    &.language {
      width: 28px;
      height: 28px;
      border-radius: 0px;
      margin: 0 5px;
      &.inactive {
        filter: grayscale(100%);
      }
    }
  }
  .avatar-img {
    img {
      width: 48px !important;
      height: 48px !important;
      object-fit: cover;
      border-radius: 8px;
    }
  }
}
</style>
