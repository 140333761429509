<template>
  <el-dialog
    class="import-file-urls-popup"
    :title="`Import file Urls`"
    :width="isContinue ? '500px': '1024px'"
    :visible="visible"
    :show-close="!importing"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <div v-if="!isContinue">
      <el-row
        class="mt-0 mb-2"
        type="flex"
        justify="space-between"
        align="middle"
      >
        <h3 class="text-center mt-0">{{ excelTitle }} ({{ filteredImportData.length }})</h3>
        <el-input
          v-model="searchText"
          class="w-50"
          placeholder="Nhập thông tin để tìm kiếm"
          @input="() => selectedRows = []"
        ></el-input>
      </el-row>
      <el-table
        :data="filteredImportData.map((d, index) => ({ ...d, stt: index + 1 }))"
        style="width: 100%"
        height="450"
      >
        <el-table-column
          key="checkbox"
          label=""
          width="50"
          fixed="left"
        >
          <template slot-scope="scope">
            <el-checkbox
              :value="selectedRows.includes(scope.row.stt)"
              @change="(v) => handleSelectRow(scope.row.stt, v)"
            ></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          v-for="column in [
            {
              key: 'stt',
              label: 'STT',
              fixed: 'left'
            },
            ...excelColumns
          ]"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
          :min-width="column.width"
          :fixed="column.fixed"
        >
          <template slot-scope="scope">
            <div class="text-limited text-limited__2" :title="scope.row[column.key]">
              {{ column.locale ? scope.row[column.locale][column.column_key] : scope.row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="mt-4" style="word-break: break-word">
      <p>
        Dữ liệu hợp lệ: {{ validData.length }}/{{ checkedImportData.length }}
      </p>
      <p>* Ghi chú: dữ liệu hợp lệ khi bảng dữ liệu có các trường bắt buộc là "url", "path", "file_type", "title", "size".</p>
      <p>
        Toàn bộ dữ liệu hợp lệ sẽ được upload và thêm mới vào hệ thống.
      </p>
      Kết quả sau khi upload: {{ createdTotal }} phần tử
    </div>
    <span slot="footer">
      <el-row type="flex" align="middle" justify="end">
        <el-button
          v-if="!isContinue"
          type="primary"
          @click="() => { isContinue = true }"
        >
          Tiếp tục
        </el-button>
        <el-button
          v-if="isContinue"
          type="info"
          class="mr-2"
          :disabled="importing"
          @click="() => { isContinue = false }"
        >
          Quay lại
        </el-button>
        <el-button
          v-if="isContinue"
          type="primary"
          :loading="importing"
          :disabled="validData.length <= 0"
          @click="() => handleImportData()"
        >
          Xác nhận
        </el-button>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { convertData } from '@/utils/import'
import { uploadStorageUrl } from '@/services/storage'

export default {
  name: 'ImportFileUrlsPopup',
  components: {},
  props: {
    visible: Boolean,
    columns: Array,
    data: Array,
    total: Number
  },
  data () {
    return {
      importing: false,
      isContinue: false,
      imported: 0,
      importSize: 10,
      selectedRows: [],
      searchText: ''
    }
  },
  computed: {
    ...mapGetters(['language', 'constant']),
    locales() {
      return this.constant.constants.locales.filter((l) => l.code !== 'vi')
    },
    excelColumns() {
      const languageColumns = this.columns.filter((c) => c.language && !(c.languageExcludeTypes || []))
      const lColumns = this.locales.map((l) => {
        return languageColumns.map((column) => ({
          ...column,
          locale: l.code,
          column_key: column.key,
          key: `${column.key}__${l.code}`,
          label: `${column.label} (${l.code})`
        }))
      }).flat()
      return [...this.columns, ...lColumns].map((c) => ({ ...c, label: c.label.toUpperCase(), field: c.key }))
    },
    excelTitle() {
      return 'Media urls'
    },
    filteredImportData() {
      return this.data.filter((d) => {
        if (this.searchText) {
          let result = false
          const keys = Object.keys(d)
          for (let i = 0; i < keys.length; i++) {
            if (d[keys[i]]) {
              if (typeof d[keys[i]] !== 'object') {
                const value = d[keys[i]].toString()
                if (value.toLowerCase().includes(this.searchText.toLowerCase())) {
                  result = true
                }
              } else {
                const oKeys = Object.keys(d[keys[i]])
                for (let j = 0; j < oKeys.length; j++) {
                  if (d[keys[i]][oKeys[j]]) {
                    if (typeof d[keys[i]][oKeys[j]] !== 'object') {
                      const value = d[keys[i]][oKeys[j]].toString()
                      if (value.toLowerCase().includes(this.searchText.toLowerCase())) {
                        result = true
                      }
                    }
                  }
                }
              }
            }
            if (result) {
              break
            }
          }
          return result
        }
        return true
      })
    },
    checkedImportData() {
      if (this.selectedRows.length > 0) {
        return this.filteredImportData.filter((d, index) => this.selectedRows.includes(index + 1))
      } else {
        return this.filteredImportData
      }
    },
    validData() {
      return this.checkedImportData.filter((d) => !!d.url && !!d.path && !!d.file_type && !!d.object_id && !!d.object_type && d.size)
    },
    createdTotal() {
      return this.total + (this.validData.length || 0)
    },
    percentage() {
      if (this.validData.length > 0) {
        return Number.parseFloat(this.imported / this.validData.length * 100).toFixed(0)
      }
      return 0
    },
    payloadData() {
      return this.validData.map((d) => {
        return convertData(d, this.columns, { ...this.constant, tags: [] })
      })
    }
  },
  methods: {
    ...mapActions('public', []),
    handleSelectRow(stt, isChecked) {
      if (isChecked) {
        this.selectedRows = [...this.selectedRows, stt]
      } else {
        this.selectedRows = this.selectedRows.filter((r) => r !== stt)
      }
    },
    async handleImportData() {
      this.importing = true
      await this.createData()
      setTimeout(() => {
        this.importing = false
        this.$emit('reload')
        this.$emit('close')
        this.$notify.success({
          title: 'Thông báo !',
          message: 'Import dữ liệu thành công !'
        })
      }, 1000)
    },

    async createData() {
      let i = 0
      while (i < this.payloadData.length) {
        const payloads = this.payloadData.slice(i, i + this.importSize)
        const requests = payloads.map((p) => {
          return this.handleUploadStorageUrl(p)
        })
        await Promise.all(requests)
        i = i + this.importSize
        this.imported = i > this.payloadData.length ? this.payloadData.length : i
      }
    },

    async handleUploadStorageUrl(payload) {
      const p = {
        url: payload.url,
        path: payload.path,
        object_id: payload.object_id,
        object_type: payload.object_type,
        file_type: payload.file_type,
        description: payload.description,
        title: payload.title,
        is_avatar: payload.is_avatar,
        size: payload.size,
        locale: 'vi'
      }
      try {
        await uploadStorageUrl(p)
      } catch (error) {
        //
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.import-file-urls-popup {
  .el-dialog__body {
    padding-top: 10px;
  }
}
</style>
