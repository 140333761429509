<template>
  <el-dialog
    top="150px"
    width="500px"
    class="upload-video"
    :title="!video ? 'Upload video mới' : 'Chỉnh sửa thông tin video'"
    :visible="visible"
    :close-on-click-modal="false"
    append-to-body
    @close="handleClosePopup"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Title" prop="title">
        <el-input v-model="form.title" :disabled="callingAPI" placeholder="Nhập vào tên video"></el-input>
      </el-form-item>
      <el-form-item label="Mô tả video" prop="description">
        <el-input
          v-model="form.description"
          type="textarea"
          :rows="3"
          :disabled="callingAPI"
          placeholder="Nhập vào mô tả video"
        ></el-input>
      </el-form-item>
      <el-form-item label="Video upload" prop="url">
        <el-row type="flex" align="middle" justify="center">
          <el-upload
            v-if="!form.url"
            v-loading="uploading"
            class="upload-demo"
            accept="video/*"
            list-type="video"
            action
            drag
            :show-file-list="false"
            :http-request="handleUploadVideo"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em>click to upload</em>
            </div>
          </el-upload>
          <el-card v-else v-loading="uploading" class="card-video">
            <div class="video">
              <img v-if="videoInfo && videoInfo.avatar" :src="videoInfo.avatar.url" alt="href" />
              <img src="@/assets/images/thumbnail-overlay.png" alt="href" />
            </div>
            <div class="info px-2">
              <el-upload
                class="upload-image"
                accept="image/*"
                list-type="image"
                action
                :show-file-list="false"
                :disabled="callingAPI"
                :http-request="handleUploadImage"
              >
                <el-button type="text">CHỌN THUMBNAIL</el-button>
              </el-upload>
            </div>
          </el-card>
        </el-row>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI" @click="handleClosePopup">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleUpdateVideo">Hoàn tất</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createStorageVideo, deleteStorages, updateStorage, createStorage } from '@/services/storage'
export default {
  name: 'UploadVideo',
  props: {
    visible: Boolean,
    type: String,
    video: Object,
    object: Object
  },
  data() {
    return {
      uploading: false,
      callingAPI: false,
      videoInfo: JSON.parse(JSON.stringify(this.video || null)),
      form: this.video
        ? JSON.parse(JSON.stringify(this.video))
        : {
          title: '',
          description: '',
          url: ''
        },
      rules: {
        title: [
          {
            required: true,
            message: 'Title không được để trống.',
            trigger: ['blur, change']
          }
        ],
        description: [
          {
            required: true,
            message: 'Mô tả không được để trống.',
            trigger: ['blur, change']
          }
        ],
        url: [
          {
            required: true,
            message: 'Video upload không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    videoData() {
      if (!this.videoInfo) {
        return null
      }
      if (this.video) {
        return {
          ...this.videoInfo,
          title: this.form.title,
          description: this.form.description,
          locale: this.language
        }
      }
      return {
        title: this.form.title,
        description: this.form.description,
        file: {},
        file_type: 'video',
        id: this.videoInfo.id,
        locale: this.language,
        object_id: this.object ? this.object.id : this.video.object_id,
        object_type: this.type ? this.type : this.video.object_type,
        avatar_id: this.videoInfo.avatar ? this.videoInfo.avatar.id : null,
        avatar: this.videoInfo.avatar
      }
    }
  },
  methods: {
    handleUpdateVideo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          updateStorage(this.videoData).then(() => {
            this.$notify.success({
              title: 'Thông báo',
              message: `${this.video ? 'Cập nhật video' : 'Upload video'} thành công`
            })
            this.callingAPI = false
            this.$emit('close')
            this.$emit('reload')
          }).catch((error) => {
            this.callingAPI = false
            this.$notify.error({
              title: 'Thông báo',
              message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
            })
          })
        }
      })
    },
    fileReader (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.readAsArrayBuffer(file)
      })
    },
    handleUploadVideo(fileInfo) {
      this.uploading = true
      this.fileReader(fileInfo.file).then((file) => {
        createStorageVideo(file).then((response) => {
          this.videoInfo = response.data
          this.form.url = this.videoInfo.url
          this.uploading = false
        }).catch((error) => {
          this.uploading = false
          this.$notify.error({
            title: 'Thông báo',
            message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
          })
        })
      })
    },
    handleClosePopup() {
      this.$emit('close')
      if (!this.video && this.videoInfo) {
        deleteStorages({
          file_type: 'video',
          object_type: this.type,
          ids: this.videoInfo.id
        })
      }
    },
    handleUploadImage(fileInfo) {
      this.uploading = true
      const params = {
        file: fileInfo.file,
        objectId: this.videoInfo.id,
        objectType: 'storage',
        fileType: 'image',
        locale: this.language,
        isAvatar: true
      }
      createStorage(params).then((response) => {
        this.videoInfo.avatar = response.data
        this.videoInfo.avatar_id = response.data.id
        this.form.url = this.videoInfo.url
        this.uploading = false
      }).catch((error) => {
        this.uploading = false
        this.$notify.error({
          title: 'Thông báo',
          message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upload-video {
}
</style>
<style lang="scss">
.upload-video {
  .el-upload-dragger {
    width: 160px;
    height: 120px;
    i {
      margin: 15px;
    }
  }
  .card-video {
    width: 48%;
    box-sizing: border-box;
    .video {
      width: 100%;
      height: 120px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        position: absolute;
      }
    }
    .info {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
