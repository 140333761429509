import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getLikes(params) {
  return request({
    url: ENDPOINT.LIKES,
    method: 'get',
    params
  })
}
